.birdsBody{
    color: lightgray;
    font-size: 25px;
    margin-top: -20px;
}
.data{
    color: black;
    margin-top: -25px;
    overflow-x: scroll;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 10px;
}
.currentData{
    -webkit-text-stroke: 1.3px black;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 32px;
    /* width: fit-content;
    margin-right: auto;
    margin-left: auto;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    background-color: gold; */
}