.App {
  min-height:100%;
  overflow: auto;
  height: 100vh; 
  width: 100%;
  color: gold;
  text-align: center;
  background-image: linear-gradient(black,gold,yellow,yellow,gold,black);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.header{
  font-size: 12px;
  padding-top: 10px;
}
.title{
  margin-top: -8px;
  /* -webkit-text-stroke-width: 1px; */
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  -webkit-text-stroke: 1.3px black;
  font-size: 35px;
  font-weight: bold;
}
.footer{
  position: fixed; 
  left: 0; 
  bottom: 0; 
  text-align: center;
  width: 100%; 
  font-size: 15px;
}