/* text: SIGHTINGS FREQUENCY is in body */
.componentBody{
    color: gray; 
    width: 100%; 
    margin-left: auto;
    margin-right: auto;
    margin-top: -15px;
    overflow: visible;
}


.dataContainer{
    margin-top: -20px;
    overflow-y: scroll;
    height: 45vh; 
  
}
/* .map data in this container */
.displayData{
    justify-content: center;
    margin-top: -30px; 
    margin-bottom: -50px;
    font-size: 20px;
    color: black; 
    display: flex;
  
}

.spec{
    color: gray;
}
